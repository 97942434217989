import { Link } from "gatsby"
import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Button from "../../components/UI/Button"
import Checkbox from "../../components/UI/Form/Checkbox"
import Input from "../../components/UI/Form/Input"

import customSolutionsImg from "../../images/custom-solutions.jpg"

const CustomSolutions = () => {
  return (
    <Layout>
      <Seo title="Custom-Solutions" />
      <div>
        <div
          className="relative h-80 lg:h-96 xl:h-screen/2 w-full bg-cover bg-no-repeat bg-center"
          style={{ backgroundImage: `url(${customSolutionsImg})` }}
        >
          <div className="absolute mix-blend-overlay bg-green-900 inset-0 w-full h-80 lg:h-96 xl:h-screen/2"></div>
          <div className="absolute inset-0 lg:container lg:mx-auto text-white px-4 xl:px-0 py-5 h-80 lg:h-96 xl:h-screen/2 flex flex-col justify-end">
            <h1 className="md:text-3xl lg:text-4xl mb-0 text-white">
              Built just for you
            </h1>
            <h2 className="text-xs md:text-sm">
              Take your business to next level with the power of technology
            </h2>
          </div>
        </div>
        <section className="container mx-auto px-5 xl:px-0 py-10">
          <div className="flex flex-col space-between pb-10">
            <h1>Custom Solutions</h1>
            <p className="mb-5">
              A unique situation or program requires unique solution. Instead of
              defaulting to excel models or affixing existing solutions that
              were not created specifically for real-estate applications, SEIN
              Technology Partners works with clients to create real-estate
              software that solves their specific problem.
            </p>
          </div>
          <p>
            Custom Solutions is currently under development. Subscribe below to
            get notified when it's available.
          </p>
          <div className="md:w-3/4 lg:w-2/3 xl:w-1/3 rounded-md p-5 bg-white mt-10 shadow-xl">
            <div>
              <Input
                label="E-Mail"
                name="email"
                value=""
                placeholder="johndoe@example.com"
                type="email"
                error=""
                required={true}
                changed={() => {}}
              />
            </div>
            <div className="text-sm mb-5">
              <Checkbox
                checkboxText="Yes, please keep me updated on Sein Tech Partners news,
                  events, webcast and special offers."
              />
              <p>
                By submitting the form, you are agreeing to our{" "}
                <Link to="/privacy-policy" className="link">
                  privacy policy.
                </Link>
              </p>
            </div>
            <div>
              <Button addClass="md:w-1/3 lg:w-1/4" clicked={() => {}}>
                Subscribe
              </Button>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default CustomSolutions
